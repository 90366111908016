import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from './login.service';

@Component({
  selector: 'payware-vpos-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  message:string;
  subscription: Subscription;

  public vloginForm = new FormGroup({
    vlogin: new FormControl('', [Validators.required])
  });
  visualizeError: boolean;

  constructor(private loginService: LoginService,
              private router: Router) { }
              // https://localhost:4201/show?vlogin=XV07D8&amount=3.23&ttl=120&currency=BGN&type=QR
  ngOnInit() {
    if (sessionStorage.getItem("user") !== null) {
      this.loginService.login(JSON.parse(sessionStorage.getItem("user")).loginId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.visualizeError = true;
          return EMPTY;
        })
      ).subscribe(() => this.router.navigate(["/home"]));
    }
  }

  login() {
    this.visualizeError = false;
    let loginFormValue = this.vloginForm.getRawValue();
    this.loginService.login(loginFormValue.vlogin)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        this.visualizeError = true;
        return EMPTY;
      })
    ).subscribe(() => this.router.navigate(["/home"]));
  }

}
