import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: any = {};

  constructor(private http: HttpClient) {}

  async loadConfig(): Promise<void> {
    const configPath = 'assets/config.json';

    try {
      const response = await this.http.get(configPath).toPromise();

      let environmentKey = 'development'; // Fallback to default

      // Explicit logic for environment detection
      const hostname = window.location.hostname;
      if (hostname === 'spos.payware.eu') {
        environmentKey = 'sandbox';
      } else if (hostname === 'webpos.payware.eu') {
        environmentKey = 'production';
      } else if (hostname === 'localhost' || hostname === '127.0.0.1') {
        environmentKey = 'development';
      }

      this.config = response[environmentKey];
    } catch (error) {
      console.error('Error loading configuration:', error);
    }
  }

  getBackendApiUrl(): string {
    return this.config?.BACKEND_API_URL || '';
  }
}
