<form clrForm [formGroup]="sharedForm" clrLayout="vertical" (ngSubmit)="submit()">

  <div style="margin-bottom: 20px;">
    <clr-input-container>
        <label>{{'text.trId' | translate}}</label>
        <input clrInput type="text" formControlName="trId" id="trId" maxlength="10"/>
        <clr-control-error>{{'text.error' | translate}}</clr-control-error>
    </clr-input-container>
  </div>

  <button class="btn btn-icon" *ngIf="sharedForm.valid" aria-label="accept" type="submit" >
    <cds-icon shape="check"></cds-icon> {{'text.accept' | translate}}
  </button>
  <button class="btn btn-icon" *ngIf="!sharedForm.valid" aria-label="accept" type="submit" disabled>
    <cds-icon shape="check"></cds-icon> {{'text.accept' | translate}}
  </button>

</form>
