import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  authorize(): Observable<any> {
    return of(!!sessionStorage.getItem('user'));
  }

}
