<div class="card-footer">
    <button type="button" class="btn btn-icon" *ngIf="buttonHomeShow" aria-label="dashboard" onclick="location.href='./dashboard';">
        <cds-icon shape="dashboard"></cds-icon> {{'sharedResources.dashboard' | translate}}
    </button>
    <button type="button" class="btn btn-icon" *ngIf="buttonDetailsShow" aria-label="details" onclick="location.href='./details';">
      <cds-icon shape="details"></cds-icon> {{'sharedResources.details' | translate}}
    </button>
    <span>&nbsp;</span>
    <button type="button" class="btn btn-icon newBill" *ngIf="buttonNewShow" aria-label="new" onclick="location.href='./home';">
        <cds-icon shape="calculator"></cds-icon> {{'sharedResources.newBill' | translate}}
    </button>
</div>
