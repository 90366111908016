import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TransactionsService } from 'src/app/shared/services/transactions.service';

@Component({
  selector: 'payware-vpos-nfc',
  templateUrl: './nfc.component.html',
  styleUrls: ['./nfc.component.scss']
})
export class NfcComponent implements OnInit {

  noNfc = false;
  visualizeError: boolean;
  notifications: string[] = [];
  isInvalidTransactionId = false;

  constructor(private transactionsService: TransactionsService, private router: Router) {
  }

  ngOnInit(): void {
    if (!("NDEFReader" in window)) {
      this.noNfc = true;
    } else {
      try {
        const ndef = new NDEFReader();
        ndef.scan().then(() => {
          console.log("> Scan started");

          ndef.addEventListener("readingerror", () => {
            console.log("Argh! Cannot read data from the NFC tag. Restart the browser and try another one?");
          });

          ndef.onreading = event => {
            this.isInvalidTransactionId = false;
            event.message.records.forEach(r => {
              if (r.recordType == "text") {
                const decoder = new TextDecoder(r.encoding);
                const text = decoder.decode(r.data);
                this.notifications.push(text);
              }
            });
            console.log('process reading', this.notifications);

            for (const notification of this.notifications) {
              if (notification.length === 10 && (notification.startsWith('pw') || notification.startsWith('pr'))) {
                this.getTrData(notification);
                return;
              }
            }
            this.isInvalidTransactionId = true;
          };
        });
      } catch (error) {
        console.log("Argh! " + error);
      }
    }
  }

  getTrData(trId: string) {
    this.transactionsService.getTrData(trId)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        this.visualizeError = true;
        return EMPTY;
      }),
      tap(scData => sessionStorage.setItem("scData", JSON.stringify(scData)))
    )
    .subscribe(() => this.router.navigate(["/home/numpad"], {  queryParamsHandling: 'preserve' }));
  }

}
