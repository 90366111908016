import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { User } from '../models/user.model';

@Component({
  selector: 'payware-vpos-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User;

  constructor(private loginService: LoginService) {
    if (sessionStorage.getItem("user") === null) {
      this.loginService.logout();
    }
  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
  }

  logout() {
    this.loginService.logout();
  }

}
