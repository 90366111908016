<div class="main-container">
    <div *ngIf="loading === true">
      Loading...
    </div>
    <div *ngIf="visualizeError && loading === false">
      {{'showResources.error' | translate}}
    </div>
    <div *ngIf="!visualizeError && loading === false" class="content-container">
        <div class="content-area">
            <div class="clr-row">
                <div class="clr-col-12">
                    <div class="card">
                        <div class="card-block" style="padding-top: 0px;">
                          <payware-vpos-header></payware-vpos-header>
                          <h3 style="margin-top: 6px; text-align-last: left;">{{'showResources.identifier' | translate}}{{trData.transactionId}}
                            <button type="button" class="btn btn-sm btn-icon logout floatR" *ngIf="!expired && ttl !== 1 && !processFlag" aria-label="share" (click)="share()">
                              <cds-icon shape="share"></cds-icon>
                            </button>
                            <button type="button" class="btn btn-sm btn-icon logout floatR" aria-label="copy" (click)="copyToClipBoardString()">
                              <cds-icon shape="copy"></cds-icon>
                            </button>
                          </h3>
                          <span class="floatR cds-text">
                            <label *ngIf="!copied && !shared">&nbsp;</label>
                            <label *ngIf="copied">{{'showResources.copied' | translate}} </label>
                            <label *ngIf="shared">{{'showResources.shared' | translate}}</label>
                          </span>
                          <h3 style="margin-top: 0px; text-align-last: left;">{{currencyCode}} {{amount.toFixed(2) | number: '1.2-2' : 'en'}}</h3>


                          <div class="card-img qrStyle" *ngIf="!processFlag">
                              <img [src]="imageData">
                          </div>

                            <table style="width: 100%;">
                              <tbody>
                                <tr>
                                  <td class="shareIcon">
                                    <button type="button" class="btn btn-sm btn-icon alignLeftLabel" *ngIf="!expired && ttl !== 1 && !processFlag" aria-label="cancel" (click)="cancel()">
                                      <cds-icon shape="times"></cds-icon> {{'showResources.cancel' | translate}}
                                    </button>
                                  </td>
                                  <td class="floatR" *ngIf="expired && ttl !== 1"><span class="expired">{{'showResources.expired' | translate}}</span></td>
                                  <td class="floatR" *ngIf="ttl === 1"><span class="expired">{{'showResources.canceled' | translate}}</span></td>
                                  <td class="floatR" *ngIf="!expired && ttl !== 1 && !processFlag">{{'showResources.expiresIn' | translate}}
                                  <countdown [config]="{leftTime: ttl}"  (event)="handleEvent($event)"></countdown></td>
                                  <td class="floatR" *ngIf="!expired && ttl !== 1 && processFlag">{{'showResources.expecting' | translate}}
                                    <countdown [config]="{leftTime: ttl}"  (event)="handleEvent($event)"></countdown></td>
                                </tr>
                              </tbody>
                            </table>
                        </div>
                        <payware-vpos-footer
                          [buttonNewShow]="buttonNewShow"
                          [buttonHomeShow]="buttonHomeShow"
                          ></payware-vpos-footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
