import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap, map } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class UserService {

  userStateStream$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient,
    private configService: ConfigService) {
   }

  getUser() {
    return this.http.get<User>(this.configService.getBackendApiUrl() + `/vpos/user`)
      .pipe(
        tap((user: User) => this.userStateStream$.next(user)),
        map((user: User) => user)
      );
  }

  getUserState(): Observable<User> {
    return this.userStateStream$.asObservable().pipe(filter((user: User) => !!user));
  }

}
