<div class="main-container">
  <div class="content-container">
    <div class="content-area">
      <div class="clr-row">
        <div class="clr-col-12">
          <div class="card">
            <div class="card-block">
              <payware-vpos-header></payware-vpos-header>

              <clr-datagrid [(clrDgSingleSelected)]="selectedTransaction" [clrDgRowSelection]="true"
                (clrDgSingleSelectedChange)="selectionChanged()">
                <clr-dg-column [clrDgField]="'created'">{{'dashboardResources.time' | translate}}</clr-dg-column>
                <clr-dg-column [clrDgField]="'amount'">{{'dashboardResources.amount' | translate}}</clr-dg-column>

                <clr-dg-row *clrDgItems="let transaction of (recentTransactions$ | async)" [clrDgItem]="transaction"
                  (click)="selected = transaction" [clrDgSelected]="selected == transaction">
                  <clr-dg-cell class="grid-column-alignment">{{transaction.created | date: 'yyyy:MM:dd'
                    }}<br>{{transaction.created | date:'HH:mm:ss'}}<br>
                    <cds-icon shape="check" status="success" *ngIf="(transaction.statusId == 1)"></cds-icon>
                    <cds-icon shape="exclamation-triangle" status="success"
                      *ngIf="(transaction.statusId == 5)"></cds-icon>
                    <cds-icon shape="times" class="is-error"
                      *ngIf="!(transaction.statusId == 1 || transaction.statusId == 5)"></cds-icon>
                    {{transaction.transactionId}}
                  </clr-dg-cell>

                  <clr-dg-cell class="grid-column-alignment">
                    <cds-icon shape="unknown-status" status="danger"></cds-icon> {{transaction.payeeAmount.toFixed(2)
                    | number: '1.2-2' : 'en' }} {{transaction.payeeCurrencyCode}}
                    <span *ngIf="(transaction.statusId == 1)"><br>
                      <cds-icon shape="download" class="is-info" style="transform: rotate(270deg)"></cds-icon>
                      {{transaction.payerAmount.toFixed(2) | number: '1.2-2' : 'en' }}
                      {{transaction.payerCurrencyCode}}<br>
                      <cds-icon shape="check" status="success"></cds-icon> {{transaction.amount.toFixed(2) | number:
                      '1.2-2' : 'en' }} {{transaction.code}}
                    </span>
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                  <clr-dg-pagination #pagination [clrDgPageSize]="5">
                    <clr-dg-page-size [clrPageSizeOptions]="[5,10,15]">{{ 'dashboardResources.transactions' | translate
                      }}</clr-dg-page-size>
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>

            </div>
              <payware-vpos-footer [buttonNewShow]="buttonNewShow" [buttonDetailsShow]="buttonDetailsShow">
              </payware-vpos-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
