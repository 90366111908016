import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { mergeMap, tap } from 'rxjs/operators';
import { LanguageService } from '../shared/services/language.service';
import { UserService } from '../shared/services/user.service';
import { CurrencyService } from '../shared/services/currency.service';
import { Currency } from '../shared/models/currency.model';
import { ConfigService } from '../shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  currencies: Currency[];

  constructor(private httpClient: HttpClient,
    private languageService: LanguageService,
    private userService: UserService,
    private currenciesService: CurrencyService,
    private configService: ConfigService) { }

  login(vlogin: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    let body = new HttpParams()
      .set('username', vlogin)
      .set('', ''); // empty password parameter

    return this.httpClient.post(this.configService.getBackendApiUrl() + `/vpos/login`, body, httpOptions)
      .pipe(
        mergeMap(() => this.userService.getUser()),
        tap(user => {
            sessionStorage.setItem('user', JSON.stringify(user));
            this.languageService.reload(user.preferredLanguageCode);

            if (sessionStorage.getItem("currencies") === null || sessionStorage.getItem("currencies").length == 0) {
              this.currenciesService.getCurrencies()
              .subscribe((currencies: Currency[]) => sessionStorage.setItem('currencies', JSON.stringify(currencies)));
            }

        })
      );
  }

  logout() {
    window.sessionStorage.clear();
    return this.httpClient.get(this.configService.getBackendApiUrl() + `/vpos/logout`)
      .subscribe(() =>  window.location.replace('/login'));
  }

  forgottenPassword(email: string) {
    return this.httpClient.post(this.configService.getBackendApiUrl() + `/vpos/user/forgotten-password`,
      { email },
      { headers: { "Content-Type": "application/json" }});
  }
}
