import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'payware-vpos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public state$: Observable<{ [key:string]: string }>;

  constructor(translateService: TranslateService,
    private router: Router) {
    translateService.setDefaultLang("en");
  }

  ngOnInit() {
    this.state$ = this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        map(() => this.router.getCurrentNavigation().extras.state)
      );
  }

}
