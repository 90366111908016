import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { Transaction } from '../models/transaction.model';
import { UserService } from './user.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private readonly TRANSACTION_LIMIT = 15;
  recentTransactionsState$: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>(null);
  user$: Observable<User>;

  constructor(private http: HttpClient,
    private userService: UserService,
    private configService: ConfigService) {
    this.user$ = this.userService.getUserState();
    this.populateRecentTransactions();
  }

  getRecentTransactions() {
    return this.http.get<Transaction[]>(this.configService.getBackendApiUrl() + "/vpos/api/dashboard/transactions?limit=" + this.TRANSACTION_LIMIT)
      .pipe(
        tap((transactions: Transaction[]) => this.recentTransactionsState$.next(transactions))
      );
  }

  getRecentTransactionsState() {
    return this.recentTransactionsState$.asObservable()
      .pipe(
        filter((transactions: Transaction[]) => !!transactions)
      );
  }

  populateRecentTransactions() {
    this.getRecentTransactions().subscribe();
  }

  formatKey(transaction: Transaction, user: User) {
    const partner = user;
    return "amount" + partner.currencyId.toLocaleLowerCase().charAt(0).toUpperCase() + partner.currencyId.slice(1).toLocaleLowerCase();
  }

}
