import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, filter } from 'rxjs/operators';
import { Account } from '../models/account.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class EWalletsService {

  walletsStateStream$: BehaviorSubject<Account[]> = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient,
    private configService: ConfigService) {
    this.getAccounts().subscribe();
  }

  getAccounts(): Observable<Account[]> {
    return this.httpClient.get<Account[]>(this.configService.getBackendApiUrl() + "/vpos/api/accounts")
      .pipe(tap((wallets: Account[]) => this.walletsStateStream$.next(wallets)));
  }

  getAccountsState() {
    return this.walletsStateStream$.asObservable()
      .pipe(
        filter((wallets: Account[]) => !!wallets)
      );
  }

}
