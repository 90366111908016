import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { mapTo, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuardService implements CanActivate {

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot) {
    // console.log('snapshot.url == ' + snapshot.url.toString());
    const vloginExists = snapshot.url.toString() === 'show' && !!snapshot.queryParams['vlogin'];
    if (vloginExists) {
      return true;
    }
    return this.authorizationService.authorize().pipe(
      catchError(() => {
        this.router.navigate(['/login']);
        return of(false);
      }),
      mapTo(true)
    );
  }
}
