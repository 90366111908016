import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Currency } from '../models/currency.model';
import { User } from '../models/user.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private httpClient: HttpClient,
    private configService: ConfigService) { }

  getCurrencies() {
    return this.httpClient.get<Currency[]>(this.configService.getBackendApiUrl() + "/vpos/api/currencies");
  }

  getSelectedCurrencies(user: User) {
    return this.httpClient.get<Currency[]>(this.configService.getBackendApiUrl() + "/vpos/api/selected-currencies/" + user.loginId);
  }
}
