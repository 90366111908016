<div class="main-container">
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row">
                <div class="clr-col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="centeredLabel">
                            <img class="loginLogo" src="https://payware.eu/assets/shared/logo-gray.svg"><span> {{'loginResources.header' | translate}}</span>
                            </div>
                        </div>
                        <div class="card-block centeredLabel">
                            <form clrForm [formGroup]="vloginForm" class="clr-form">
                                <input type="text" id="vlogin"  formControlName="vlogin" name="vlogin"
                                placeholder="{{'loginResources.vlogin' | translate}}" class="clr-input loginInput" autofocus>
                            </form>
                            <button type="button" class="btn btn-outline loginStart" (click)="login()">
                              {{'loginResources.vPOS' | translate}}
                          </button>
                        </div>
                        <div class="card-header paywareCardFooter">

                            <div>
                            <span class="not-registered" [innerHTML]="'loginResources.not_registered_label' | translate"></span>
                            </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
