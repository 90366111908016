<div class="main-container">
  <div class="content-container">
    <div class="content-area">
      <div class="clr-row">
        <div class="clr-col-12">
          <div class="card">
            <div class="card-block">
              <payware-vpos-header></payware-vpos-header>
              <clr-tabs>
                <clr-tab *ngFor="let subComponent of subComponents">
                  <button clrTabLink [routerLink]="subComponent.path" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <img style="height: 24px;" src="{{subComponent.imgSrc}}">
                    <!-- {{subComponent.label | translate}} -->
                  </button>
                </clr-tab>
              </clr-tabs>
              <div id="tabs-content">
                <router-outlet></router-outlet>
              </div>
            </div>
            <payware-vpos-footer [buttonNewShow]="buttonNewShow" [buttonHomeShow]="buttonHomeShow">
            </payware-vpos-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>