import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'payware-vpos-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input()
  buttonNewShow: boolean;
  @Input()
  buttonHomeShow: boolean;
  @Input()
  buttonDetailsShow: boolean;


  constructor() { }

  ngOnInit() {
  }

}
