import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService) {

    if (sessionStorage.getItem("user") === null || sessionStorage.getItem("user").length == 0) {
      translate.setDefaultLang('en')
    } else {
      const retrievedObject = sessionStorage.getItem('user');
      const user = JSON.parse(retrievedObject);
      translate.setDefaultLang(user.preferredLanguageCode)
    }

  }

  public reload(languageCode: string) {
    this.translate.setDefaultLang(languageCode);
  }

}
