import { Component, OnInit } from '@angular/core';
import * as Bowser from "bowser";
import { User } from '../shared/models/user.model';

@Component({
  selector: 'payware-vpos-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  buttonHomeShow: boolean;
  buttonNewShow: boolean;
  userState: User;
  subComponents: Array<{ imgSrc: string, label: string, path: string }> = [];

  constructor() { }

  ngOnInit(): void {
    this.buttonHomeShow = true;
    this.buttonNewShow = true;
    this.userState = JSON.parse(sessionStorage.getItem("user"));
    const browser = Bowser.getParser(window.navigator.userAgent);

    this.subComponents.push({ imgSrc: './assets/img/calculator.svg', label: 'home.numpad', path: 'numpad' })

    if (this.userState.cameraOn) {
      this.subComponents.push({ imgSrc: './assets/img/scan_icon_gray.svg', label: 'home.scanner', path: 'scanner' })
    };

    if (this.userState.nfcon && browser.satisfies({ chrome: ">=89" })) {
      this.subComponents.push({ imgSrc: './assets/img/nfc.svg', label: 'home.nfc', path: 'nfc' })
    };

    if (this.userState.typeTrIdOn) {
      this.subComponents.push({ imgSrc: './assets/img/text-line.svg', label: 'home.text', path: 'text' })
    };

  }

}
