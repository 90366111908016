<div class="nfc">
  <label *ngIf="noNfc" style="width: 200px; text-align: center;" [innerHTML]="'nfc.no_nfc' | translate"></label>
  <h4>{{'nfc.tap' | translate}}</h4>
  <img style="width: 160px; margin-top: 20px;" src="./assets/img/nfc.svg">
  <br>
  <div *ngIf="isInvalidTransactionId">
    <p *ngFor="let notification of notifications">{{ notification }}</p>
  </div>
</div>

