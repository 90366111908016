import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import Keyboard from "simple-keyboard";
import { EWalletsService } from 'src/app/shared/services/ewallets.service';
import { LoginService } from '../../login/login.service';
import { Currency } from '../../shared/models/currency.model';
import { User } from '../../shared/models/user.model';
import { CurrencyService } from '../../shared/services/currency.service';
import { TransactionsService } from '../../shared/services/transactions.service';

@Component({
  selector: 'payware-vpos-numpad',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss']
})
export class NumpadComponent implements OnInit {

  message: string;

  buttonHomeShow: boolean;
  buttonNewShow: boolean;

  visualizeError: boolean;

  keyboard: Keyboard;
  imageFlag: string;
  timeToLive: string;
  userSubscription$: Subscription;
  userState: User;
  selectedCurrencies: Currency[];
  amount: number;
  subscription: Subscription;
  settings: any;
  remember: boolean;
  selectedCurrency: string;
  processFlag: boolean;
  scData: any;
  accountCurrencies: string[] = [];

  public numpadForm;
  public transactionData: any;

  constructor(private currenciesService: CurrencyService,
    private router: Router,
    private transactionsService: TransactionsService,
    private accountsService: EWalletsService,
    private loginService: LoginService) {
    if (sessionStorage.getItem("user") === null) {
      this.loginService.logout();
    }
  }

  ngOnInit() {
    this.buttonHomeShow = true;
    this.buttonNewShow = true;
    this.remember = false;
    this.processFlag = false;
    this.userState = JSON.parse(sessionStorage.getItem("user"));
    sessionStorage.setItem("processing", JSON.stringify(false));

    this.accountsService
      .getAccountsState()
      .subscribe((accounts) => {
        this.accountCurrencies = accounts
          .map((acc) => acc.currencyCode)
          .filter((currencyCode) => !!currencyCode);
      });

    if (sessionStorage.getItem("scData") === null || sessionStorage.getItem("scData").length == 0) {
      if (sessionStorage.getItem("selectedCurrencies") === null || sessionStorage.getItem("selectedCurrencies").length == 0) {
        this.currenciesService.getSelectedCurrencies(this.userState)
          .pipe(
            tap((selectedCurrencies: Currency[]) => this.selectedCurrencies = selectedCurrencies)
          )
          .subscribe((selectedCurrencies: Currency[]) => sessionStorage.setItem('selectedCurrencies', JSON.stringify(selectedCurrencies)));
      } else {
        this.selectedCurrencies = JSON.parse(sessionStorage.getItem("selectedCurrencies"));
      }
      if (sessionStorage.getItem("settings") === null || sessionStorage.getItem("settings").length == 0) {
        this.imageFlag = this.userState.numpadQROn ? "QR"
          : this.userState.numpadBarOn ? "BARCODE"
            : this.userState.numpadShareOn ? "PLAIN"
              : "QR";
        this.timeToLive = "120";
        this.amount = null;
        this.selectedCurrency = this.userState.currencyId;
        this.remember = false;
        this.processFlag = false;
        this.toSettings();
      } else {
        this.settings = JSON.parse(sessionStorage.getItem("settings"));
        this.imageFlag = this.settings.imageFlag;
        this.timeToLive = this.settings.timeToLive;
        this.amount = this.settings.amount;
        this.selectedCurrency = this.settings.currencyCode;
        this.remember = this.settings.remember;
        this.processFlag = this.settings.processFlag;
      }
    } else {
      this.scData = JSON.parse(sessionStorage.getItem("scData"));
      this.scData.reasonL2 = this.scData.reasonL1;
      this.scData.reasonL1 = "webPOS: " + this.userState.loginId;
      this.amount = this.scData.amount;
      this.selectedCurrency = this.scData.currency;
      this.processFlag = true;
      sessionStorage.setItem("processing", JSON.stringify(true));
    }

    this.resetForm();
  }

  toSettings() {
    this.settings = {
      imageFlag: this.imageFlag,
      timeToLive: this.timeToLive,
      amount: this.amount,
      currencyCode: this.selectedCurrency,
      remember: this.remember,
      processFlag: false
    }
    sessionStorage.setItem("settings", JSON.stringify(this.settings));
  }

  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onKeyPress: button => this.onKeyPress(button),
      inputPattern: /^[0-9]*$/,
      autoUseTouchEvents: true,
      preventMouseDownDefault: true,
      disableCaretPositioning: true,
      newLineOnEnter: false,
      tabCharOnTab: false,
      maxLength: 25,
      disableButtonHold: true,
      physicalKeyboardHighlight: true,
      syncInstanceInputs: true,
      layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"]
      },
      display: {
        "{bksp}": "\u2716",
        "{enter}": "\u2714"
      },
      buttonTheme: [
        {
          class: "btn btn-outline paywareButtons",
          buttons: "1 2 3 4 5 6 7 8 9 0"
        },
        {
          class: "btn btn-outline redButton paywareButtons",
          buttons: "{bksp}"
        },
        {
          class: "btn btn-outline greenButton paywareButtons",
          buttons: "{enter}"
        }
      ],
      theme: "hg-theme-default hg-layout-numeric numeric-theme paywareKeypadTheme"
    });

    /**
     * Physical Keyboard support
     * Whenever the input is changed with the keyboard, updating SimpleKeyboard's internal input
     */
    /*document.addEventListener("keydown", event => {
      // Disabling keyboard input, as some keys (like F5) make the browser lose focus.
      // If you're like to re-enable it, comment the next line and uncomment the following ones
      event.preventDefault();
    });*/

    document.getElementById("amount").addEventListener("input", event => {
      let input = (<any>document.getElementById("amount")).value;
      this.keyboard.setInput(input);
    });

    // Prevents opening a keyboard on mobile device
    // document.getElementById("amount").focus();

    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];
    document.querySelector("body").addEventListener("keyup", event => {
      if ('Enter' === event.key) {
        if (this.processFlag) {
          this.processTransaction();
        } else {
          this.generateTransaction();
        }
      }
      if (allowedKeys.indexOf(event.key) > -1) {
        if (document.activeElement && document.activeElement.id !== 'amount') {
          const amountInput = document.getElementById("amount");
          amountInput.focus();

          this.recalculateValue(event.key);
        }
      }
    });
  }

  recalculateValue(key?: string) {
    const amountInput = document.getElementById("amount");
    const decimalValue = +(<any>amountInput).value;

    if (key) {
      if (key === 'Backspace' || key === 'Delete') {
        if (isNaN(decimalValue) || 0 === decimalValue) {
          // do nothing
        } else {
          const newValue = (<any>amountInput).value.replace('.', '').slice(0, -1);
          (<any>amountInput).value = (parseFloat(newValue) / 100).toFixed(2);
        }
      } else {
        if (isNaN(decimalValue) || 0 === decimalValue) {
          (<any>amountInput).value = '0.0' + key;
        } else {
          const newValue = (<any>amountInput).value.replace('.', '') + key;
          (<any>amountInput).value = (parseFloat(newValue) / 100).toFixed(2);
        }
      }
    } else {
      if (isNaN(decimalValue) || 0 === decimalValue) {
        (<any>amountInput).value = '0.00';
      } else {
        const newValue = (<any>amountInput).value.replace('.', '');
        (<any>amountInput).value = (parseFloat(newValue) / 100).toFixed(2);
      }
    }
  }

  onKeyPress = (button: string) => {
    const key = button === '{bksp}' ? 'Backspace' : button;
    if (key === "{enter}") {
      if (this.processFlag) {
        this.processTransaction();
      } else {
        this.generateTransaction();
      }
    } else {
      this.recalculateValue(key);
    }
  };

  onInputChange(event) {
    this.recalculateValue(event.key);
  }

  private generateTransaction() {
    sessionStorage.removeItem("scData");
    this.visualizeError = false;
    let numpadFormValue = this.numpadForm.getRawValue();
    const amountInput = document.getElementById("amount");
    numpadFormValue.amount = (<any>amountInput).value;
    sessionStorage.setItem("amount", numpadFormValue.amount);
    sessionStorage.setItem("currencyCode", numpadFormValue.currencyCode);
    let expires = +Date.now() + numpadFormValue.timeToLive * 1000;
    sessionStorage.setItem("expires", expires.toString());
    this.saveSettings(numpadFormValue);

    this.transactionsService.generate(numpadFormValue)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.visualizeError = true;
          return EMPTY;
        }),
        tap(trData => sessionStorage.setItem("trData", JSON.stringify(trData)))
      ).subscribe(() => numpadFormValue.imageFlag === 'PLAIN'
        ? this.router.navigate(["/share"])
        : this.router.navigate(["/show"])
      );
  }

  private processTransaction() {
    sessionStorage.removeItem("scData");
    this.visualizeError = false;
    let numpadFormValue = this.numpadForm.getRawValue();
    const amountInput = document.getElementById("amount");
    numpadFormValue.amount = (<any>amountInput).value;
    this.scData.amount = numpadFormValue.amount;
    sessionStorage.setItem("amount", numpadFormValue.amount);
    sessionStorage.setItem("currencyCode", numpadFormValue.currencyCode);
    let expires = numpadFormValue.timeToLive != null ? +Date.now() + numpadFormValue.timeToLive * 1000 : +this.scData.created + this.scData.timeToLive * 1000;
    this.scData.timeToLive = numpadFormValue.timeToLive != null ? numpadFormValue.timeToLive : null;
    sessionStorage.setItem("expires", expires.toString());
    this.saveSettings(numpadFormValue);

    this.transactionsService.process(this.userState.shopCode, this.scData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.visualizeError = true;
          return EMPTY;
        }),
        tap(trData => sessionStorage.setItem("trData", JSON.stringify(trData)))
      )
      .subscribe(() => this.router.navigate(["/show"]));
  }

  private resetForm() {
    this.numpadForm = new FormGroup({
      shop: new FormControl(this.userState.shopCode),
      amount: new FormControl(this.amount),
      currencyCode: new FormControl({ value: this.selectedCurrency, disabled: !this.userState.allowedCurrencyChange }),
      reasonL1: new FormControl("webPOS: " + this.userState.loginId),
      imageFlag: new FormControl(this.imageFlag),
      timeToLive: new FormControl({ value: this.timeToLive, disabled: !this.userState.numpadDurationOn }),
      remember: new FormControl({ value: this.remember, disabled: !this.userState.numpadRememberOn })
    });
  }

  saveSettings(numpadFormValue: any) {
    if (numpadFormValue.remember) {
      this.imageFlag = numpadFormValue.imageFlag;
      this.timeToLive = numpadFormValue.timeToLive;
      this.amount = numpadFormValue.amount;
      this.selectedCurrency = numpadFormValue.currencyCode;
      this.remember = numpadFormValue.remember;
      this.toSettings();
    } else {
      this.imageFlag = "QR";
      this.timeToLive = "120";
      this.amount = null;
      this.selectedCurrency = this.userState.currencyId;
      this.remember = false;
      this.toSettings();
    }
  }

  logout() {
    this.loginService.logout();
  }

}
