<div class="main-container">
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row">
                <div class="clr-col-12">
                    <div class="card">
                         <div class="card-block">
                          <payware-vpos-header></payware-vpos-header>
                          <h4 class="alJ">{{'detailsResources.identifier' | translate}}{{prData.transactionId}}</h4>
                          <h4 class="top6 alJ">{{'detailsResources.currency' | translate}}{{currency}}</h4>
                          <h4 class="top6 alJ">{{'detailsResources.amount' | translate}}{{amount.toFixed(2) | number: '1.2-2' : 'en' }}</h4>
                          <h4 class="top6 alL">{{'detailsResources.created' | translate}}</h4>
                          <h4 class="top0 alR">{{prData.created | date: 'yyyy:MM:dd' }} {{prData.created | date:'HH:mm:ss'}}</h4>
                          <h4 class="top6 alL" *ngIf="isNotEarlyDate(prData.finalized)">{{ 'detailsResources.finalized' | translate }}</h4>
                          <h4 class="top0 alR" *ngIf="isNotEarlyDate(prData.finalized)">{{ prData.finalized | date: 'yyyy:MM:dd HH:mm:ss' }}</h4>
                          <h4 class="top6 alJ"*ngIf="prData.status == 'CONFIRMED'" >{{'detailsResources.status' | translate}}<span style="float: right;">{{'detailsResources.CONFIRMED' | translate}} <cds-icon shape="check" status="success"></cds-icon></span></h4>
                          <h4 class="top6 alJ"*ngIf="prData.status == 'DECLINED'">{{'detailsResources.status' | translate}}<span style="float: right;">{{'detailsResources.DECLINED' | translate}} <cds-icon shape="times" class="is-error"></cds-icon></span></h4>
                          <h4 class="top6 alJ"*ngIf="prData.status == 'FAILED'">{{'detailsResources.status' | translate}}<span style="float: right;">{{'detailsResources.FAILED' | translate}} <cds-icon shape="times" class="is-error"></cds-icon></span></h4>
                          <h4 class="top6 alJ"*ngIf="prData.status == 'EXPIRED'">{{'detailsResources.status' | translate}}<span style="float: right;">{{'detailsResources.EXPIRED' | translate}} <cds-icon shape="times" class="is-error"></cds-icon></span></h4>
                          <h4 class="top6 alJ"*ngIf="prData.status == 'ACTIVE'">{{'detailsResources.status' | translate}}<span style="float: right;">{{'detailsResources.ACTIVE' | translate}} <cds-icon shape="exclamation-triangle" status="success"></cds-icon></span></h4>
                          <h4 class="top6 alJ"*ngIf="prData.status == 'CANCELLED'">{{'detailsResources.status' | translate}}<span style="float: right;">{{'detailsResources.CANCELLED' | translate}} <cds-icon shape="times" class="is-error"></cds-icon></span></h4>
                          <h4 class="top6 alR" style="text-align: right;">{{prData.statusMessage}}</h4>

                          <clr-accordion>
                            <clr-accordion-panel>
                              <clr-accordion-title>{{'detailsResources.image' | translate}}</clr-accordion-title>
                              <clr-accordion-content *clrIfExpanded>
                                <qrcode
                                  [qrdata]="prData.transactionId"
                                  [width]="qrWidth"
                                  [scale]="qrScale"
                                  [version]="qrVersion"
                                  [errorCorrectionLevel]="qrErrorCorrectionLevel"
                                  [elementType]="qrElementType"
                                  ></qrcode>
                              </clr-accordion-content>
                            </clr-accordion-panel>
                          </clr-accordion>

                        </div>
                        <payware-vpos-footer
                        [buttonNewShow]="buttonNewShow"
                        [buttonHomeShow]="buttonHomeShow"
                        ></payware-vpos-footer>
                      </div>

                </div>
            </div>
        </div>
    </div>
</div>
