import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TransactionsService } from '../../shared/services/transactions.service';

@Component({
  selector: 'payware-vpos-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit {

  allowedFormats = [ BarcodeFormat.QR_CODE,  BarcodeFormat.CODE_128 ];
  resultString: string;
  visualizeError: boolean;
  notification: string;
  buttonHomeShow : boolean;
  buttonNewShow : boolean;

  constructor(private transactionsService: TransactionsService, private router: Router) { }

  ngOnInit(): void {
    this.buttonHomeShow = true;
    this.buttonNewShow = true;
  }

  clearResult(): void {
    this.resultString = null;
  }

  onCodeResult(resultString: string) {
    this.getTrData(resultString);
  }

  getTrData(result: String) {
    this.transactionsService.getTrData(result)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        this.visualizeError = true;
        return EMPTY;
      }),
      tap(scData => sessionStorage.setItem("scData", JSON.stringify(scData)))
    )
    .subscribe(() => this.router.navigate(["/home/numpad"], {  queryParamsHandling: 'preserve' }));

  }

}
