
<div>
  <h4 class="top0">
    <span *ngIf="user.representation !== 'IND'"><a href="http://api.payware.eu/{{ user.merchantId }}" target="_blank">{{ user.shopCode }}</a></span>
    <span *ngIf="user.representation === 'IND'">
      {{ 'sharedResources.individual-account' | translate }}:
      <a href="http://api.payware.eu/{{ user.merchantId }}" target="_blank">{{ user.merchantId }}</a>
    </span>
    <span class="floatR">
      <button type="button" class="btn btn-sm btn-icon logout" aria-label="logout" (click)="logout()" style="margin-top: 0px; margin-bottom: 0px;">
        <cds-icon shape="logout"></cds-icon>
      </button>
    </span>
  </h4>
  <h4 class="top6">{{user.merchantName}}
    <span class="floatR">
      <a href="https://payware.eu/">
        <img class="height24" src="https://www.payware.eu/assets/shared/payware-certified-icon.svg">
      </a>
    </span>
  </h4>
</div>
