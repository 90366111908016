import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Currency } from '../shared/models/currency.model';
import { Transaction } from '../shared/models/transaction.model';
import { User } from '../shared/models/user.model';
import { CurrencyService } from '../shared/services/currency.service';
import { DashboardService } from '../shared/services/dashboard.service';

@Component({
  selector: 'payware-vpos-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  buttonNewShow : boolean;
  buttonDetailsShow : boolean;
  selectedTransaction: any;
  currencies: Currency[];
  recentTransactions$: Observable<Transaction[]>;
  userState: User;
  selected: Transaction;

  constructor(private dashboardService: DashboardService,
    private loginService: LoginService,
    private currenciesService: CurrencyService) {
      if (sessionStorage.getItem("user") === null) {
        this.loginService.logout();
      }
      if (sessionStorage.getItem("currencies") === null || sessionStorage.getItem("currencies").length == 0) {
        this.currenciesService.getCurrencies()
          .subscribe((currencies: Currency[]) => sessionStorage.setItem('currencies', JSON.stringify(currencies)));
      }
      this.currencies = JSON.parse(sessionStorage.getItem("currencies"));
      this.userState = JSON.parse(sessionStorage.getItem("user"));
      this.recentTransactions$ = this.dashboardService.getRecentTransactionsState();
      this.recentTransactions$
        .pipe(
          tap(x=> this.selected = x[0]),
        ).subscribe(); // TODO: this is triggered once after template rendering hence doesn't set the 'selected' on time !!
    }

  formatAmount(transaction: Transaction, user: User) {
    return transaction[this.dashboardService.formatKey(transaction, user)];
  }

  ngOnInit() {
    this.buttonNewShow = true;
    this.buttonDetailsShow = false;
  }


  selectionChanged() {
    sessionStorage.setItem("prData", JSON.stringify(this.selectedTransaction));
    this.buttonDetailsShow = true;
  }

  logout() {
    this.loginService.logout();
  }

}
