import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { ClrAlertModule, ClrIconModule, ClrFormsModule, ClrCommonFormsModule, ClrDatagridModule, ClrDropdownModule, ClrSignpostModule, ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { appRoutingModule } from './app.routing';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ShowComponent } from './show/show.component';
import { DetailsComponent } from './details/details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { CountdownModule } from 'ngx-countdown';
import { LanguageService } from './shared/services/language.service';
import { UnauthorizedInterceptor } from './shared/interceptors/unauthorized-interceptor';
import { QRCodeModule } from 'angularx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import '@cds/core/alert/register.js';
import { HomeComponent } from './home/home.component';
import { NumpadComponent } from './home/numpad/numpad.component';
import { ScannerComponent } from './home/scanner/scanner.component';
import { NfcComponent } from './home/nfc/nfc.component';
import { ShareComponent } from './share/share.component';
import { TextComponent } from './home/text/text.component';
import { ConfigService } from './shared/services/config.service';

import {
  ClarityIcons, checkIcon, exclamationTriangleIcon, timesIcon, unknownStatusIcon, downloadIcon,
  infoCircleIcon, copyIcon, shareIcon, dashboardIcon, detailsIcon, calculatorIcon, logoutIcon
} from '@cds/core/icon';

ClarityIcons.addIcons(checkIcon, exclamationTriangleIcon, timesIcon, unknownStatusIcon, downloadIcon,
  infoCircleIcon, copyIcon, shareIcon, dashboardIcon, detailsIcon, calculatorIcon, logoutIcon);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
function initializeApp(configService: ConfigService) {
  return () => configService.loadConfig();
}
@NgModule({
  declarations: [
    AppComponent,
    NumpadComponent,
    ScannerComponent,
    ShowComponent,
    DetailsComponent,
    DashboardComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    NfcComponent,
    ShareComponent,
    TextComponent
  ],
  imports: [
    BrowserModule,
    ClrAlertModule,
    ClrFormsModule,
    ClrCommonFormsModule,
    ClrDatagridModule,
    FormsModule,
    ClrDropdownModule,
    ReactiveFormsModule,
    ClrSignpostModule,
    ClrIconModule,
    BrowserAnimationsModule,
    appRoutingModule,
    HttpClientModule,
    CountdownModule,
    ZXingScannerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClarityModule,
    QRCodeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
       multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    LanguageService
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AppModule { }
