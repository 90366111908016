<div>

  <form [formGroup]="numpadForm" clrForm clrLayout="vertical">

    <div *ngIf="(processFlag == false )" style="display: flex; margin-bottom: 8px;">
      <input clrToggle type="checkbox" formControlName="remember" name="remember" autofocus />
      <label style="margin-left: -1em; flex: 1;">{{ 'numpadResources.remember' | translate }}</label>
      <clr-select-container style="margin-top: 0;">
        <select style="min-width: 5em;" clrSelect formControlName="currencyCode" name="currencyCode" #SelectedCurrency
          (change)=(SelectedCurrency.value)>
          <option *ngFor="let currency of selectedCurrencies" [value]="currency.code">
            {{currency.code}}
          </option>
        </select>
      </clr-select-container>

      <clr-signpost *ngIf="!accountCurrencies.includes(SelectedCurrency.value)">
        <cds-icon clrSignpostTrigger shape="exclamation-triangle" status="warning"></cds-icon>
        <clr-signpost-content *clrIfOpen [clrPosition]="'bottom-left'">
          <p [innerHTML]="'numpadResources.no_account_warning' | translate" style="margin-top: 0px;"></p>
        </clr-signpost-content>
      </clr-signpost>

    </div>

    <div *ngIf="(processFlag)">
      <div class="centeredLabel">
        <h4 class="alJ" style="margin-top: 0px;">{{'numpadResources.identifier' | translate}}{{scData.transactionId}}
        </h4>
        <h4 class="top4 alJ">{{'numpadResources.currency' | translate}} {{scData.currency}}</h4>
        <h4 class="top4 bottom4 alJ">{{'numpadResources.reason' | translate}} {{scData.reasonL1}} <br></h4>
      </div>
    </div>

    <div *ngIf="userState.numpadQROn || userState.numpadBarOn || userState.numpadShareOn">
      <clr-radio-container clrInline layout="compact" style="align-items: center; margin-top: 6px; margin-bottom: 6px;">
        <clr-radio-wrapper *ngIf="userState.numpadQROn">
          <input type="radio" clrRadio name="imageFlag" formControlName="imageFlag" value="QR"
            [(ngModel)]="imageFlag" />
          <label>{{ 'numpadResources.QRcode' | translate }}</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper *ngIf="userState.numpadBarOn">
          <input type="radio" clrRadio name="imageFlag" formControlName="imageFlag" value="BARCODE"
            [(ngModel)]="imageFlag" />
          <label>{{ 'numpadResources.barcode' | translate }}</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper *ngIf="userState.numpadShareOn">
          <input type="radio" clrRadio name="imageFlag" formControlName="imageFlag" value="PLAIN"
            [(ngModel)]="imageFlag" />
          <label style="margin-right: 0px;">{{ 'numpadResources.share' | translate }}</label>
        </clr-radio-wrapper>
      </clr-radio-container>
    </div>

    <div style="text-align: center;margin-bottom: 8px;" *ngIf="userState.numpadDurationOn">
      <div class="btn-group btn-outline-primary btn-sm modified-btn-group">
        <div class="radio btn">
          <input type="radio" formControlName="timeToLive" name="timeToLive" id="btn-radio-2" value="120">
          <label for="btn-radio-2">2 {{'numpadResources.min' | translate}}</label>
        </div>
        <div class="radio btn">
          <input type="radio" formControlName="timeToLive" name="timeToLive" id="btn-radio-6" value="360">
          <label for="btn-radio-6">6 {{'numpadResources.min' | translate}}</label>
        </div>
        <div class="radio btn">
          <input type="radio" formControlName="timeToLive" name="timeToLive" id="btn-radio-10" value="600">
          <label for="btn-radio-10">10 {{'numpadResources.min' | translate}}</label>
        </div>
      </div>
    </div>

    <clr-signpost>
      <cds-icon clrSignpostTrigger shape="info-circle" class="signpost-trigger btn btn-link"
        style="margin-right: 4px; height: 26px; width: 20px;"></cds-icon>
      <clr-signpost-content *clrIfOpen [clrPosition]="'bottom-right'">
        <p [innerHTML]="'numpadResources.defaultValues' | translate" style="margin-top: 0px;"></p>
      </clr-signpost-content>
    </clr-signpost>

    <input type="text" id="amount" formControlName="amount" placeholder="0.00" class="clr-input billInput90"
      (input)="onInputChange($event)" maxlength="25" digitOnly decimal="true">

  </form>

  <div class="simple-keyboard"></div>

</div>
