import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TransactionsService } from 'src/app/shared/services/transactions.service';

@Component({
  selector: 'payware-vpos-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  sharedForm: FormGroup;
  visualizeError: boolean;

  constructor(private transactionsService: TransactionsService,private router: Router) {
    this.sharedForm = new FormGroup({
      trId: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ])
    }, { validators: this.checkTrId });
  }

  ngOnInit(): void {
  }

  checkTrId(group: FormGroup) {
    const error = { pwrTransaction: false };
    const trIdControl = group.controls.trId;
    if (trIdControl.value && (trIdControl.value.startsWith('pw') || trIdControl.value.startsWith('pr'))) {
      return null;
    } else {
      trIdControl.setErrors(error);
    }
    return error;
  }

  submit(){
    this.getTrData(this.sharedForm.getRawValue().trId);
  }

  getTrData(result: String) {
    this.transactionsService.getTrData(result)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        this.visualizeError = true;
        return EMPTY;
      }),
      tap(scData => sessionStorage.setItem("scData", JSON.stringify(scData)))
    )
    .subscribe(() => this.router.navigate(["/home/numpad"], {  queryParamsHandling: 'preserve' }));

  }

}
